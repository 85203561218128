<template>
  <ion-row class="ion-margin-top ion-margin-bottom ion-justify-content-start">
    <ion-text class="xLargeFont ion-align-self-start">
      {{ "Lluvia Caída en " + anio }}
    </ion-text>
  </ion-row>
  <ion-list lines="full" v-for="(m, index) in mmMeses" :value="m" :key="m.mes">
    <ion-item
      @click="goToMes(m, index)"
      button="true"
      type="button"
      class="itemBorder"
    >
      <ion-grid fixed>
        <ion-row class="ion-align-items-center">
          <ion-col class="smallFont">
            <ion-text>
              {{ m.mes }}
            </ion-text>
          </ion-col>
          <ion-col class="smallFont" style="float:right">
            <ion-text>
              {{ m.mm.mm && m.mm.mm !== 0 ? m.mm.mm + " mm" : "--" }}
            </ion-text>
          </ion-col>
          <ion-col size="auto">
            <ion-icon
              :md="icons.edit"
              :ios="icons.edit"
              color="dark"
            ></ion-icon>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-item>
  </ion-list>

  <ion-modal
    :is-open="modalEditMes"
    @didDismiss="modalEditMes = false"
    :backdropDismiss="false"
    :scroll-events="true"
  >
    <ion-content :scroll-events="true">
      <ion-card style="max-width: 100% !important">
        <ion-row>
          <ion-card-title
            class="ion-margin-top"
            style="margin-left:16px; font-weight: bold;"
          >
            {{ "Lluvia caída" }}
          </ion-card-title>
        </ion-row>
        <ion-row class="ion-align-items-center">
          <ion-col>
            <ion-card-title
              class="ion-margin-top"
              style="margin-left:16px; font-weight: bold; font-size: 22px;"
            >
              {{ selectedMes.mes + " de " + anio }}
            </ion-card-title>
          </ion-col>
          <ion-col class="ion-margin-top" size="auto">
            <ion-label style="font-size: 16px; margin-right:16px;">
              {{ "Total del Mes: " + totalMmMes + " mm" }}
            </ion-label>
          </ion-col>
        </ion-row>
        <ion-card-content>
          <ion-row class="ion-justify-content-end">
            <FullCalendar :options="calendarOptions" style="width: 100%" />
          </ion-row>
          <ion-row class="ion-float-right">
            <ion-col>
              <ion-button @click="closeModalMes">
                Volver
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-modal>

  <ion-modal
    :is-open="modalEditMM"
    @didDismiss="modalEditMM = false"
    :backdropDismiss="false"
    :scroll-events="true"
  >
    <ion-content :scroll-events="true">
      <ion-row class="ion-justify-content-center">
        <ion-card style="max-width: 55% !important">
          <ion-row>
            <ion-card-title
              class="ion-margin-top"
              style="margin-left:16px; font-weight: bold; white-space: normal;"
            >
              {{
                "Lluvia caída para el día " + selectedDia.dia + "/" + mesIndex
              }}
            </ion-card-title>
          </ion-row>
          <ion-card-content>
            <ion-row class="ion-align-items-center" v-if="selectedDia.dia">
              <ion-col>
                <ion-item class="itemBorder">
                  <ion-input
                    v-model="selectedDia.mmDia"
                    autocapitalize="off"
                    type="text"
                    inputmode="text"
                    autocomplete="off"
                    style="font-size: 16px; text-align: right;"
                  ></ion-input>
                </ion-item>
              </ion-col>
              <ion-col size="auto">
                <ion-label style="font-size: 16px">
                  {{ "ㅤmm" }}
                </ion-label>
              </ion-col>
            </ion-row>
            <ion-row class="ion-float-right">
              <ion-col>
                <ion-button
                  fill="outline"
                  class="ion-margin-top"
                  color="medium"
                  @click="closeModalMM"
                >
                  Cancelar
                </ion-button>
              </ion-col>
              <ion-col>
                <ion-button
                  class="ion-float-right ion-margin-top"
                  @click="submit"
                >
                  Aceptar
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-card-content>
        </ion-card>
      </ion-row>
    </ion-content>
  </ion-modal>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import {
  IonModal,
  IonContent,
  IonCard,
  IonCardTitle,
  IonCardContent,
  IonRow,
  IonCol,
  IonGrid,
  IonList,
  IonItem,
  IonButton,
  IonIcon,
  IonLabel,
  IonInput,
  IonText,
  alertController
} from "@ionic/vue";
import { Lluvias } from "@/api";
import { pencilOutline } from "ionicons/icons";
import "@fullcalendar/core/vdom"; // solves problem with Vite
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

export default {
  components: {
    IonCard,
    IonCardTitle,
    IonContent,
    IonCardContent,
    IonModal,
    IonItem,
    IonRow,
    IonButton,
    IonLabel,
    IonCol,
    IonGrid,
    IonText,
    IonInput,
    IonList,
    IonIcon,
    FullCalendar
  },

  props: {
    success: { type: Boolean, default: false },
    error: { type: String, default: "" },
    establecimiento: { type: Object, default: () => ({}) },
    anio: { type: String, default: new Date().getFullYear() }
  },

  data: () => ({
    lluvia: undefined,
    mmMeses: [
      { mes: "Enero", mm: { mm: 0, mmDias: [] } },
      { mes: "Febrero", mm: { mm: 0, mmDias: [] } },
      { mes: "Marzo", mm: { mm: 0, mmDias: [] } },
      { mes: "Abril", mm: { mm: 0, mmDias: [] } },
      { mes: "Mayo", mm: { mm: 0, mmDias: [] } },
      { mes: "Junio", mm: { mm: 0, mmDias: [] } },
      { mes: "Julio", mm: { mm: 0, mmDias: [] } },
      { mes: "Agosto", mm: { mm: 0, mmDias: [] } },
      { mes: "Septiembre", mm: { mm: 0, mmDias: [] } },
      { mes: "Octubre", mm: { mm: 0, mmDias: [] } },
      { mes: "Noviembre", mm: { mm: 0, mmDias: [] } },
      { mes: "Diciembre", mm: { mm: 0, mmDias: [] } }
    ],
    totalMmMes: 0,
    selectedMes: {},
    mesIndex: 0,
    modalEditMes: false,
    mmModal: {
      mm: 0,
      mmDias: []
    },
    icons: {
      edit: pencilOutline
    },
    selectedDia: {},
    calendarOptions: {
      plugins: [dayGridPlugin, interactionPlugin],
      initialView: "dayGridMonth",
      headerToolbar: false,
      events: []
    },
    modalEditMM: false
  }),

  computed: {
    ...mapState(["user"]),
    ...mapGetters(["isAdmin"]),
    editing() {
      return this.lluvia && this.lluvia._id ? this.lluvia._id : undefined;
    },
    idProductor() {
      return this.$route.params.idProductor;
    },
    idLote() {
      return this.$route.params.idLote;
    }
  },

  async mounted() {
    await this.getMe();
    await this.dataSync();
    this.calendarOptions.dateClick = this.handleDateClick;
  },

  watch: {
    async establecimiento() {
      await this.dataSync();
    },
    async idLote() {
      await this.dataSync();
    },
    async idProductor() {
      await this.dataSync();
    },
    async anio() {
      await this.dataSync();
      this.closeModalMes();
    }
  },

  methods: {
    ...mapActions(["getMe"]),

    handleDateClick: function(arg) {
      this.selectedDia = this.mmModal.mmDias.find(
        d => d.dia === arg.date.getDate()
      );
      this.modalEditMM = true;
    },

    async dataSync() {
      this.lluvia = await this.getLluvia();
      if (this.editing) {
        this.mmMeses[0].mm = this.lluvia.mmEnero;
        this.mmMeses[1].mm = this.lluvia.mmFebrero;
        this.mmMeses[2].mm = this.lluvia.mmMarzo;
        this.mmMeses[3].mm = this.lluvia.mmAbril;
        this.mmMeses[4].mm = this.lluvia.mmMayo;
        this.mmMeses[5].mm = this.lluvia.mmJunio;
        this.mmMeses[6].mm = this.lluvia.mmJulio;
        this.mmMeses[7].mm = this.lluvia.mmAgosto;
        this.mmMeses[8].mm = this.lluvia.mmSeptiembre;
        this.mmMeses[9].mm = this.lluvia.mmOctubre;
        this.mmMeses[10].mm = this.lluvia.mmNoviembre;
        this.mmMeses[11].mm = this.lluvia.mmDiciembre;
      } else {
        this.mmMeses[0].mm = { mm: 0, mmDias: [] };
        this.mmMeses[1].mm = { mm: 0, mmDias: [] };
        this.mmMeses[2].mm = { mm: 0, mmDias: [] };
        this.mmMeses[3].mm = { mm: 0, mmDias: [] };
        this.mmMeses[4].mm = { mm: 0, mmDias: [] };
        this.mmMeses[5].mm = { mm: 0, mmDias: [] };
        this.mmMeses[6].mm = { mm: 0, mmDias: [] };
        this.mmMeses[7].mm = { mm: 0, mmDias: [] };
        this.mmMeses[8].mm = { mm: 0, mmDias: [] };
        this.mmMeses[9].mm = { mm: 0, mmDias: [] };
        this.mmMeses[10].mm = { mm: 0, mmDias: [] };
        this.mmMeses[11].mm = { mm: 0, mmDias: [] };
      }
    },

    getDateStr(anio, mes, dia) {
      if (mes >= 10) {
        if (dia >= 10) {
          return anio + "-" + mes + "-" + dia;
        }
        return anio + "-" + mes + "-0" + dia;
      }
      if (dia >= 10) {
        return anio + "-0" + mes + "-" + dia;
      }
      return anio + "-0" + mes + "-0" + dia;
    },

    goToMes(mes, index) {
      this.selectedMes = mes;
      this.mesIndex = index + 1;
      this.mmModal.mmDias = Array.apply(
        null,
        Array(
          this.getDaysInMonth(
            this.anio ? this.anio : new Date().getFullYear(),
            this.mesIndex
          )
        )
      ).map(function(x, i) {
        return {
          dia: i + 1,
          mmDia: ""
        };
      });
      if (this.editing) {
        this.cargarMmDias();
      }
      this.calendarOptions.initialDate = this.getDateStr(
        this.anio,
        this.mesIndex,
        1
      );
      this.totalMmMes =
        this.selectedMes && this.selectedMes.mm && this.selectedMes.mm.mm
          ? this.selectedMes.mm.mm
          : 0;
      this.modalEditMes = true;
      setTimeout(function() {
        window.dispatchEvent(new Event("resize"));
      }, 100);
    },

    cargarMmDias() {
      this.calendarOptions.events = [];
      this.selectedMes.mm.mmDias.forEach(dia => {
        if (dia.mmDia && dia.mmDia !== 0) {
          this.mmModal.mmDias[dia.dia - 1].mmDia = dia.mmDia;
          this.calendarOptions.events.push({
            title: dia.mmDia + " mm",
            date: this.getDateStr(this.anio, this.mesIndex, dia.dia)
          });
        }
      });
    },

    closeModalMes() {
      this.modalEditMes = false;
      this.selectedMes = {};
      this.mesIndex = 0;
      this.mmModal = {
        mm: 0,
        mmDias: []
      };
    },

    closeModalMM() {
      this.modalEditMM = false;
      this.selectedDia = {};
    },

    cambiarValorMes() {
      let mmTotal = 0;
      this.mmModal.mmDias.forEach(dia => {
        if (dia.mmDia && dia.mmDia !== 0) {
          mmTotal += Number(dia.mmDia);
        }
      });
      this.mmModal.mm = mmTotal;
      this.totalMmMes = mmTotal;
    },

    getDaysInMonth(year, month) {
      return Number(new Date(year, month, 0).getDate());
    },

    async getLluvia() {
      const { data, error } = await Lluvias.get({
        query: {
          establecimiento: this.establecimiento._id,
          anio: this.anio
        },
        sort: { createdAt: -1 },
        populate: "establecimiento"
      });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo datos de Lluvia.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        return alert.present();
      }
      if (
        data[0] &&
        data[0].establecimiento._id ===
          this.$route.params.idEstablecimiento.toString()
      ) {
        return data[0];
      }
      return undefined;
    },

    async submit() {
      this.$emit("update:success", false);
      let error = "";
      this.mmModal.mmDias.forEach(dia => {
        if (isNaN(dia.mmDia)) {
          error +=
            "<li>El campo <b>" +
            dia.dia +
            "/" +
            this.mesIndex +
            "</b> es numérico." +
            `<br/></li>`;
        } else if (dia.mmDia < 0) {
          error +=
            "<li>El campo <b>Día " +
            dia.dia +
            "</b> debe ser un número positivo." +
            `<br/></li>`;
        }
      });
      if (!error) {
        this.cambiarValorMes();
        if (this.mmModal.mm !== 0) {
          const index = this.mmMeses.findIndex(
            m => m.mes == this.selectedMes.mes
          );
          this.mmMeses[index].mm = this.mmModal;
        }
        this.$emit("submit", {
          lluvia: {
            _id: this.lluvia && this.lluvia._id ? this.lluvia._id : undefined,
            anio: this.anio,
            mmEnero: this.mmMeses[0].mm,
            mmFebrero: this.mmMeses[1].mm,
            mmMarzo: this.mmMeses[2].mm,
            mmAbril: this.mmMeses[3].mm,
            mmMayo: this.mmMeses[4].mm,
            mmJunio: this.mmMeses[5].mm,
            mmJulio: this.mmMeses[6].mm,
            mmAgosto: this.mmMeses[7].mm,
            mmSeptiembre: this.mmMeses[8].mm,
            mmOctubre: this.mmMeses[9].mm,
            mmNoviembre: this.mmMeses[10].mm,
            mmDiciembre: this.mmMeses[11].mm,
            establecimiento: this.establecimiento
          }
        });
        this.cargarMmDias();
        this.closeModalMM();
      } else {
        const alert = await alertController.create({
          header: "Error al editar datos de Lluvia",
          message: error,
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
    }
  }
};
</script>

<style scoped>
.modal-wrapper {
  background: transparent !important;
}

ion-content {
  --background: transparent;
  margin-right: auto;
}

ion-modal {
  --ion-background-color: #ffffff85;
}

@media only screen and (min-width: 1024px) {
  ion-list {
    max-width: 55% !important;
    margin-left: auto;
    margin-right: auto;
  }
  ion-card {
    max-width: 55% !important;
  }
}

ion-card-content {
  max-width: 100% !important;
}

.whiteFontColor {
  --ion-color-constrast: #ffffff;
}

.negrita {
  font-size: 175%;
  margin-left: 0px;
  font-weight: bold;
}

.xLargeFont {
  font-size: x-large;
  font-weight: bold;
}

.margen4px {
  margin-left: 4px;
}

.margen8px {
  margin-left: 8px;
}

.right {
  display: block;
  margin-left: auto;
  margin-right: 8px;
  float: right;
}

.width25 {
  width: 25%;
}

.leftMargin2vh {
  margin-left: 2vh;
}

.margen9pxLados {
  margin-left: 9px;
  margin-right: 9px;
}

.smallFont {
  font-size: small;
}

.itemBorder {
  --border-color: var(--ion-color-secondary);
  --min-height: 12px;
}

.itemColor {
  --background: rgba(var(--ion-color-primary-rgb), 0.6);
  --border-color: rgba(var(--ion-color-primary-rgb), 0.6);
}

.margen8pxArriba {
  margin-top: 8px;
}
</style>
