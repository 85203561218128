<template>
  <ion-page>
    <ion-content :scroll-events="true">
      <div id="container" class="marginBottom4px">
        <ion-card color="light">
          <ion-card-header>
            <ion-row class="ion-align-items-center">
              <ion-col size="auto">
                <ion-card-title
                  style="white-space: normal; font-size: 16px;"
                  class="ion-text-capitalize"
                >
                  Establecimiento:
                  <b style="font-size: 20px">{{ nombreEstablecimiento }}</b>
                </ion-card-title>
              </ion-col>
              <ion-col>
                <ion-item>
                  <ion-label position="floating">Año</ion-label>
                  <ion-select
                    :value="anio"
                    @ionChange="anio = $event.target.value"
                  >
                    <ion-select-option v-for="a in anios" :value="a" :key="a">
                      {{ a }}
                    </ion-select-option>
                  </ion-select>
                </ion-item>
              </ion-col>
            </ion-row>
          </ion-card-header>
          <ion-card-content>
            <FichaLluvia
              :error="error"
              @submit="submitEdit"
              :establecimiento="establecimiento"
              :anio="anio"
            />
          </ion-card-content>
        </ion-card>
        <ion-button
          @click="volver"
          class="ion-margin-top ion-float-end margen9pxLados whiteFontColor"
          color="primary"
        >
          Volver
        </ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonLabel,
  IonRow,
  IonCol,
  IonSelect,
  IonSelectOption,
  IonItem,
  IonPage,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  alertController
} from "@ionic/vue";
import { Establecimientos, Lluvias } from "@/api";
import { mapActions, mapState, mapGetters } from "vuex";
import FichaLluvia from "../../components/FichaLluvia.vue";

export default {
  components: {
    IonContent,
    IonLabel,
    IonRow,
    IonCol,
    IonSelect,
    IonSelectOption,
    IonItem,
    IonPage,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    FichaLluvia
  },
  data: () => ({
    establecimiento: "",
    anio: "",
    anios: []
  }),
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["isAdmin"]),
    idEstablecimiento() {
      return this.$route.params.idEstablecimiento;
    },
    nombreEstablecimiento() {
      return this.establecimiento && this.establecimiento.nombre
        ? this.establecimiento.nombre
        : "";
    }
  },
  async mounted() {
    await this.getMe();
    await this.dataSync();
    setTimeout(() => {
      this.anio = new Date().getFullYear();
    }, 100);
  },
  watch: {
    user() {
      this.dataSync();
    }
  },
  methods: {
    ...mapActions(["getMe"]),
    async dataSync() {
      this.establecimiento = await this.getEstablecimiento();
      this.anios = this.getYearsSince(2015);
    },

    getYearsSince(year) {
      let years = [];
      let currentYear = new Date().getFullYear();
      while (currentYear >= year) {
        years.push(currentYear);
        currentYear--;
      }
      return years;
    },

    async getEstablecimiento() {
      const { data, error } = await Establecimientos.getBy_id(
        this.idEstablecimiento
      );
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo establecimiento",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
      return data;
    },

    async submitEdit({ lluvia }) {
      this.error = "";
      const { /*data,*/ error } = await Lluvias.save(lluvia);
      if (error) {
        this.error = error;
      } else {
        this.success = true;
        const alert = await alertController.create({
          header: "Lluvia",
          message: "Datos de Lluvia Actualizados",
          buttons: [
            {
              text: "Aceptar",
              handler: () => {
                this.dataSync();
              }
            }
          ]
        });
        alert.present();
      }
    },

    volver() {
      this.$router.back();
    }
  }
};
</script>

<style scoped>
.leftMargin2vh {
  margin-left: 2vh;
}

.margen8pxArriba {
  margin-top: 8px;
}

.marginBottom4px {
  margin-bottom: 4px;
}

.whiteFontColor {
  --ion-color-constrast: #ffffff;
}

.margen9pxLados {
  margin-left: 9px;
  margin-right: 9px;
}

@media only screen and (min-width: 1024px) {
  #container {
    width: 50% !important;
    overflow: hidden !important;
    margin-left: 43px;
  }
  ion-item {
    max-width: 150px;
    margin-left: 12px;
    margin-right: auto;
  }
}
</style>
